.quizMain {
  background-color: #f1f5f9;
  min-height: 100vh;
}

.overviewContainer {
  margin: 0 auto;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: rgb(128, 128, 128);
  padding: 3vw;
  background-color: #fff;
}

.overviewContainer .quizTitle {
  font-size: 1.6rem;
  text-align: center;
}

.overviewContainer .descriptionContainer {
  margin-top: 1rem;
}

.descriptionContainer .description {
  margin: 1rem 0rem 2rem 0rem;
  margin-left: 1.5rem;
}

.overviewContainer .instructionsContainer {
  margin-top: 1.5rem;
  text-align: justify;
}

.instructionsContainer .instructions {
  margin: 0.5rem 0rem 2rem 0rem;
}

.instructionsContainer .instructions ul {
}

.instructionsContainer .instructions ul li {
  list-style: circle;
  letter-spacing: 0.7px;
  list-style-type: decimal;
  padding-top: 1rem;
}

.detailContainer {
  display: flex;
  align-items: center;
  gap: 1.3rem;
}

.detailContainer .detail {
  display: flex;
  flex-direction: column;
}

.detailContainer .detail .title {
  font-size: 1.4rem;
  font-weight: bold;
}

.detailContainer .detail .desc {
  font-size: 1.1rem;
  color: grey;
  margin-bottom: 5px;
}

.detailContainer .detailIcon {
  border-radius: 50px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 1.8rem;
}

.detailContainer .detailIcon.questions {
  background-color: rgba(95, 45, 237, 0.1);
  color: var(--primary);
}

.detailContainer .detailIcon.timer {
  background-color: rgba(61, 193, 252, 0.1);
  color: #3dc1fc;
}

.detailContainer .detailIcon.marks {
  color: #fe678e;
  background-color: rgba(254, 103, 142, 0.1);
}

.detailContainer .detailIcon.pass {
  color: #1bc48b;
  background-color: rgba(27, 196, 139, 0.1);
}

.detailContainer .detailIcon.attemptsRemain {
  color: red;
  background-color: rgba(255, 98, 71, 0.1);
}

.detailContainer .detailIcon.attemptsTaken {
  color: #aaa;
  background-color: rgba(204, 204, 204, 0.1);
}

.headerContainer {
  display: flex;
  max-height: 55px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.headerText {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerText > h4 {
  margin: 0;
  color: #666666;
}

.searchContainer>div {
  margin: 0;
}

table tbody th, table tbody td {
  padding: 10px !important;
}
table tbody th{
  text-align: center !important;
}

.overviewContainer {
  max-width: 85vw;
}

.wrapper {
  width: 100%;
  max-width: 250px;
  margin: 6rem auto;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.2rem;
  background: #fff;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 15px;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

@media only screen and (min-width: 900px) {
  .quizTable {
    max-width: 85vw !important;
    /* margin: 0 auto; */
  }
}
