.dummyHeaderContainer {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
}

.dummyHeader {
  position: relative;
  height: 250px;
  background-color: #9195F6;
}

.dummyHeader p {
  position: absolute;
  top: 30%;
  text-align: center;
  color: #fff;
  width: 100%;
  margin-bottom: 0;
  margin-top: 10px;
}

.quizCard {
  position: absolute;
  width: 90%;
  max-width: 900px;
  width: 0 auto;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 20px 50px -20px,
    rgba(0, 0, 0, 0.3) 0px 20px 50px -30px;
  border-radius: 10px;
}

.quizCardHeader {
  height: 55px;
  padding: 5px 20px;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quizCardHeader>p {
  margin-bottom: 0;
}

.quizCardHeader .timerContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.quizCardHeader .timerContainer .timer {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 15px;
}

.exitButton {
  padding: 5px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: rgba(254, 97, 95, 0.15);
  color: #fe615f;
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 6px;
  text-align: center;
  height: 30px;
  line-height: 20px;
  min-width: 100px;
  font-size: 15px;
}

.quizCardContent {
  position: relative;
  display: grid;
  grid-template-columns: 70% 30%;
}

.quizCardContent .questionContainer {
  border-right: 1px solid #ccc;
  position: relative;
  min-height: 75vh;
}

.questionContainer {
  padding: 12px 1.5rem 1.5rem;
}

.quizCardContent .questionContainer.fullScreen {
  min-height: 60vh;
}

.question {
  font-size: 16px;
  letter-spacing: 0.4px;
  text-align: justify;
}

.question {
  color: rgb(71, 85, 105);
}

.reviewContainer {
  padding: 2rem 1rem 0 1rem;
  max-height: 400px;
  overflow-y: scroll;
}

.reviewContainer .reviewButton {
  background-color: rgb(238, 238, 245);
  transition: all ease 0.3s;
  color: #868af8;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 10px;
  width: 100%;
}

.reviewContainer .reviews {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));
  gap: 0.5rem;
  grid-auto-rows: 40px;
}

.review {
  padding: 1rem;
  background-color: #f4f6fb;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  transition: all 0.5s ease;
}

.review {
  color: rgb(71, 85, 105);
}

.review.active {
  background-color: rgb(155, 187, 199) !important;
  color: #fff !important;
  font-weight: bold;
}

.review.answered {
  background-color: rgba(91, 227, 177, 0.805);
  color: #fff;
  font-weight: bold;
}

.review.reviewMarkedAnswered {
  background-color: #e9b09d;
  color: #fff;
  font-weight: bold;
}

.review.reviewMarkedNotAnswered {
  background-color: #e69e86;
  border: 1.5px solid #fdf3ea;
  color: #fff;
  font-weight: bold;
}

.optionsContainer {
  background-color: rgb(198, 215, 221);
  margin-top: 1rem;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.optionsContainer .option {
  background-color: #fff;
  padding: 10px 1rem;
  border-radius: 5px;
  margin-top: 7px;
}

.optionsContainer .option:first-child {
  margin-top: 0;
}

.optionsContainer .option.selected {
  background-color: #e5f8f1;
}

.option span{
  color: rgb(71, 85, 105);
}

.quizCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.quizCardFooter button {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #868af8;
  color: #fff;
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 10px;
  min-width: 80px;
}


.quizCardFooter button.disabled {
  background-color: #b1b3f7;
  cursor: not-allowed;
}

.fullScreenMode {
  position: absolute;
  right: 0%;
  bottom: -10%;
}

.questionContainer .question.blank input {
  border: none;
  min-width: 200px;
  border-bottom: 1px solid grey;
  outline: none;
  margin: 0rem 1rem;
  font-size: 18px;
  transition: all ease 0.4s;
  text-align: center;
}

.questionContainer .question.blank input:focus {
  border-bottom: 1px solid var(--primary);
}

.reviewTable td {
  font-size: 0.8rem;
  font-weight: lighter;
}

.viewButton {
  background-color: #fff;
  color: blue;
}

.statContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  box-shadow: 0px 2px 1px -1px rgba(211, 217, 227, 0.3), 0px 1px 1px 0px rgba(211, 217, 227, 0.25), 0px 1px 3px 0px rgba(211, 217, 227, 0.22);
  border-radius: 3px;
  box-shadow: #ccc 1px 2px 12px;
  padding: 5px;
}

.statContainer > div {
  padding: 5px;
  font-size: 15px;
  color: rgb(71, 85, 105);;
}

.contentText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.groupHeader {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
}

@media only screen and (min-width: 768px) {
  .reviewContainer {
    min-height: 75vh;
  }
}

@media (max-width: 768px) {
  .dummyHeaderContainer {
    display: none;
  }
  .quizCard {
    width: 100%;
    position: relative;
    background-color: #fff;
  }
  .quizCardHeader {
    flex-direction: column;
    height: auto;
    gap: 1rem;
  }

  .fullScreenMode {
    display: none;
  }

  .quizCardHeader .timerContainer {
    font-size: 1.2rem;
  }

  .quizCardContent {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }

  .optionsContainer {
    width: 100%;
  }

  .quizCardContent .questionContainer {
    min-height: 70vh;
  }

  .reviewContainer {
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
  }
}
