.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-right: 15px;
}

.studentsNumText {
  font-size: 20px;
}

.editorClass {
  min-height: 400px; /* Minimum height of the editor */
  height: auto; /* Allows the editor to expand as needed */
  border: 1px solid #ccc; /* Optional: Add a border to make the editor more visible */
  padding: 10px; /* Optional: Add padding for better readability */
}

.emailsContainer {
  display: flex;
  flex-wrap: wrap;
}

.emailContainer {
  display: flex;
  padding: 5px 10px;
  font-size: 14px;
  justify-content: space-between;
  background-color: rgb(145, 217, 241);
  border-radius: 10px;
  margin-right: 10px;
}