.resultImg {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
}
