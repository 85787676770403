.logo-container>img {
  max-width: 100%;
}

.logo-container {
  max-width: 100%;
  text-align: center;
  margin: 24px;
  margin-bottom: 22px;
}

.main>.container {
  width: 256px;
}

.container {
  background-color: rgb(12, 15, 42);
}

.main {
  width: 256px;
  /* position: fixed;
  top: 0;
  left: 0; */
  height: 100vh;
  background-color: rgb(12, 15, 42);
  /* overflow-y: scroll; */
}

.top-curve,
.bottom-curve {
  background-color: rgb(12, 15, 42);
  position: absolute;
  width: 25px;
  height: 25px;
}

.top-curve>div,
.bottom-curve>div {
  background-color: #fff;
  width: 25px;
  height: 25px;
}

.top-curve>div {
  border-top-left-radius: 25px;
  /* border-bottom-right-radius: 30px; */
}

.bottom-curve>div {
  border-bottom-left-radius: 25px;
  /* border-top-right-radius: 30px; */
}

.top-curve {
  top: 0px;
  right: -25px;
  /* border-bottom-right-radius: 29px; */
}

.bottom-curve {
  bottom: 0px;
  right: -25px;
  /* border-top-right-radius: 29px; */
}

.item-container {
  padding: 0 16px;
  letter-spacing: 0.8px;
  font-size: 15px;
}

.item {
  cursor: pointer;
  line-height: 56px;
  color: rgba(148, 163, 184, 0.85);
}

.active {
  color: rgb(107, 66, 221);
  border-radius: 10px;
  background-color: #211c49;
}

.itemText {
  font-weight: 500;
}

.icon {
  margin: 0px 16px 0px 21px;
}

.sectionContainer {
  display: flex;
  padding: 10px;
  background-color: rgb(12, 15, 42);
  box-shadow: rgba(31, 45, 61, 0.15) 0px 2px 2px 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
}

.menuIcon {
  color: rgb(107, 66, 221);
  margin-left: 15px;
}

.drawIcon {
  color: rgb(152, 124, 229) !important;
}

.sectionText {
  color: #fff;
  font-size: 20px;
  letter-spacing: 1.1px;
  line-height: 46px;
  text-transform: capitalize;
}

.courseActive {
  width: 100%;
  background-color: #fff;
  color: rgb(56, 63, 72) !important;
}

.container::-webkit-scrollbar {
  display: none;
}

.globalSearchContainer {
  margin: 0 16px 20px;
  padding: 9px 13px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: start;
  border: none;
  outline: none;
  background: transparent;
  caret-color: rgb(226, 232, 240);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: text;
  border-radius: 8px;
  border: 1px solid rgba(148, 163, 184, 0.85);
  color: rgba(148, 163, 184, 0.85);
}

.globalSearchInput {
  width: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: start;
  color: rgba(148, 163, 184, 0.85);
  border: none;
  background: transparent;
  font-style: normal;
  outline: none;
  margin-left: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  caret-color: rgb(226, 232, 240);
}

.globalSearchInput::placeholder {
  color: rgba(148, 163, 184, 0.85);
}

@media only screen and (min-width: 950px) {
  .sectionContainer {
    display: none;
  }
  .courseActive {
    display: block;
  }

  .container {
    max-height: 100vh;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 950px) {

  .top-curve,
  .bottom-curve,
  .main {
    display: none;
  }

  .sectionContainer {
    display: block;
  }

  .container {
    height: 100vh;
    overflow-y: scroll;
  }
}