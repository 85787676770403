.scrollButton {
  background-color: #e0e1e1;
  border-radius: 50px;
  padding: 0.8rem;
  position: fixed;
  bottom: 3%;
  right: 2%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.scrollButton.visible {
  opacity: 1;
}
.scrollIcon {
  font-size: 1.2rem;
}

.scrollButton:hover {
  transform: scale(1.2);
}

.courseView {
  padding: 0 !important;
}

.studentContent {
  height: 100vh;
  /* padding: 28px; */
}

@media only screen and (min-width: 950px) {
  .mainContainer {
    display: flex;
  }

  .top-bottom-left-border {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
}

@media only screen and (max-width: 950px) {
  .outlet {
    margin-top: 66px;
  }
}
