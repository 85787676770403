.mainSidebar {
  /* padding: 24px; */

}

.profileContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.nameContainer {
  padding-left: 12px;
  font-weight: 500;
}

.nameContainer>div {
  margin-bottom: 7px;
}

.leaderboard {
  display: flex;
  color: rgb(14, 116, 144);
  cursor: pointer;
  font-size: 16px;
  letter-spacing: normal;
  line-height: 24px;
}

.leaderboard>div:first-child {
  margin-right: 5px;
}

.currDate {
  font-size: 14px;
  color: rgb(100, 116, 139);
}

.dataContainer {
  display: flex;
  justify-content: space-around;
  padding: 15px 15px 24px;
}

.coinsContainer,
.pointsContainer {
  display: flex;
}

.coinsContainer>div:first-child,
.pointsContainer>div:first-child {
  margin-right: 8px;
}

.coinsText,
.pointsText {
  color: rgb(100, 116, 139);
  font-size: 14px;
}

.studentName {
  font-size: 14px;
  line-height: 24px;
  color: rgb(30, 41, 59);
}

.coins,
.points {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: normal;
  line-height: 28px;
  color: rgb(51, 65, 85);
}

.dailyGoalContainer,
.weeklyGoalContainer,
.supportContainer {
  padding: 15px;
  border-radius: 16px;
  border: 1px solid rgb(203, 213, 225);
  background: #f1f5f9;
}

.dailyGoalHeader {
  display: flex;
  justify-content: space-between;
}

.headerText {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgb(51, 65, 85);
}

.supportContainer {
  margin-top: 15px;
}

.supportText {
  font-size: 16px;
}

.timeLeft {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  margin-left: 4px;
  color: rgb(22, 163, 74);
  font-weight: 500;
}

.clockIcon {
  margin-right: 5px;
}

.ranksText {
  display: flex;
  font-size: 14px;
}

.arrowContainer {
  display: flex;
  margin-left: 3px;
  margin-top: -2px;
}

.rightArrow>svg {
  width: 12px;
  height: 12px;
}

.rightArrow {
  padding-top: 0.8px;
}

.progressBar {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.progressBar svg {
  border-radius: 50%;
  background: #fff;
}

.dailyGoalText {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-top: 8px;
  font-size: 12px;
  letter-spacing: normal;
  display: flex;
  font-weight: 500;
  line-height: 30px;
  border: 0.7px solid rgb(203, 213, 225);
  color: #8F384D;
  padding: 0 16px;
  background-color: #fff;
}

.dailyGoalText>div {
  margin-right: 3px;
  /* font-size: 14px; */
}

.dailyGoalText>div:last-child {
  margin-right: 0;
}

.weeklyGoalContainer {
  margin-top: 15px;
}

.weeksContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.goalsReached {
  font-size: 16px;
  color: rgb(37, 99, 235);
  font-weight: 600;
}

.weekText {
  font-size: 14px;
  color: rgb(51, 65, 85);
}

.weeks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.weekContainer {
  /* margin-left: 20px; */
}

.weekContainer>div {
  margin-right: 15px;
}

.weekContainer>div:last-child {
  margin-right: 0;
}

.weekWord {
  font-size: 12px;
}

.todayDot {
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: rgb(244, 63, 94);
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: 2.5px;
}

.supportContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.supportIcons {
  display: flex;
  justify-content: space-between;
}

.supportIcons svg {
  width: 35px;
  height: 35px;
}

.supportIcons>div:first-child {
  margin-right: 15px;
}

.supportIcons a {
  color: #FA7070;
}

.supportIcons a:hover {
  color: #FA7070;
}

.goalImg {
  margin-top: -4px;
}

.weeklyGoalContainer .goalImg {
  margin-top: -5px;
}