.circular-progress-container {
  display: inline-block;
  position: relative;
}

.circular-progress {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.background-circle {
  stroke-linecap: round;
}

.progress-circle {
  stroke-linecap: round;
}

.progress-text {
  font-size: 16px;
}

.childrenContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}