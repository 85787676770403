.mainContainer {
  padding: 28px 28px 15px;
}

.difficultyLevel {
  width: 80px;
  min-width: 80px;
  padding: 3px 5px;
  text-align: center;
  border-radius: 5px;
}

.easyLevel {
  background-color: #D6EFD8;
  color: #1A5319;
}

.mediumLevel {
  background-color: #F5E7B2;
  color: #FF7F3E;
}

.hardLevel {
  color: #E4003A;
  background-color: #ffcbb1;
}

.pageTitle{
  color: #666666;
}