.sectionDuration {
  margin-top: 5px;
  font-size: 11px;
  margin-bottom: 0.5rem;
}

.resourcesButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 1rem;
  border-color: rgb(128, 154, 202) !important;
}

.banner {
  width: 100%;
  height: 420px;
  object-fit: contain;
}

.container {
  display: flex;
}

.videoSections::-webkit-scrollbar {
  display: none;
}

.about {
  padding: 0 20px 20px;
  max-width: 950px;
  margin: 0 auto 0;
  color: rgb(71, 85, 105);
  padding-top: 1.4rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bolder;
}

.title span {
  color: grey;
  font-weight: normal;
}

.headline {
  margin-top: 10px;
  color: #505153;
}

.description {
  margin-top: 1rem;
}

.videoContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0.5rem;
}

.videoContainer:hover {
  /* background-color: #121d36; */
}

.videoTitle {
  color: rgb(128, 154, 202);
  font-size: 14px;
}

.durationContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 5px;
}

.durationContainer .duration {
  font-size: 0.8rem;
  color: rgb(128, 154, 202);
}

.videoContainer .active,
.videoContainer.active {
  background-color: #121d36;
  color: #E8F9FD;
}

.videoSections {
  height: auto;
  max-height: calc(100vh - 66px);
  height: calc(100vh - 66px);
  background: rgb(24, 38, 66);
  overflow-y: auto;
  scroll-behavior: smooth;
  position: fixed;
  left: 0;
  width: 300px;
  transform: translateX(0);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 100;
}

.hide {
  max-height: 0;
  overflow: hidden;
}

.mainSectionContainer {
  padding: 10px 10px 0px;
  border: 1px solid #d3d9e3;
  border-radius: 5px;
  background-color: #fff;
}

.sectionText {
  display: flex;
  justify-content: space-between;
  color: rgb(71, 85, 105);
}

.sections {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  cursor: pointer;
}

.section {
  height: 25px;
  width: 25px;
  margin-right: 8px;
  margin-bottom: 12px;
  border-radius: 3px;
  padding: 3px;
  line-height: 19px;
  border: 1px solid #d3d9e3;
  text-align: center;
  color: rgb(71, 85, 105);
}

.activeSection {
  color: #fff;
  background-color: rgba(76, 40, 175, 0.85);
}

.videoActionContainer {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.videoActionContainer button {
  background-color: rgb(27, 43, 80, 0.84);
  color: #e5e9ee;
  border-radius: 8px;
  text-align: center;
  padding: 3px 8px;
  min-width: 70px;
  font-size: 14px;
}

.videoActionContainer .btnDisabled {
  background-color: rgba(27, 43, 80, 0.75);
  cursor: not-allowed;
}

.titleContainer {
  color: rgb(27, 43, 110);
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  min-height: 40px;
}

.titleContainer>div {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.titleContainer>div:last-child {
  margin-right: 0;
}

.sectionTitle {
  font-size: 15px;
  letter-spacing: 0.8px;
}

.videoPlayer {
  width: 750px;
}

.main {
  margin-left: 300px;
}

.contentText {
  font-size: 15px;
  color: #46a8ff;
  padding: 6px;
  width: 100px;
}

.contentText::after {
  content: "";
  position: relative;
  z-index: 99;
  width: 85%;
  display: block;
  height: 1.5px;
  background-color: rgb(70, 168, 255);
  color: black;
  bottom: -6px;
}

.toggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleIcon {
  color: rgb(70, 168, 255);
  cursor: pointer;
}

.toggleIcon>svg {
  height: 22px;
  width: 22px;
}

.forwardArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 46px;
  height: 40px;
  cursor: pointer;
  background: #00306e;
  color: #fff;
}

.sectionPath,
.videoPath {
  min-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.videoTitleWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pointsContainer {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding-right: 10px;
}

.pointsContainer>span {
  margin-right: 5px;
}

.pointsContainer>span:last-child {
  margin-right: 0;
}

.greenTickContainer,
.emptyCircle {
  margin-top: -3px;
  margin-right: 15px;
}

.emptyCircle {
  align-self: flex-start;
  margin-top: 5px;
  width: 11px;
  height: 11px;
  background: transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(128, 154, 202);
  flex-shrink: 0;
}

.videoBefore::before {
  content: "";
  top: -6px;
  position: absolute;
  width: 2px;
  z-index: 99;
  height: 10px;
  background-color: rgb(128, 154, 202);
  transform-origin: center top;
  margin-left: 4.5px;
}

.videoAfter::after {
  content: "";
  bottom: -6px;
  position: absolute;
  width: 2px;
  z-index: 99;
  top: 37px;
  background-color: rgb(128, 154, 202);
  transform-origin: center top;
  margin-left: 4.5px;
}

.videoTitleContainer {
  max-width: 200px;
}

@media only screen and (min-width: 950px) {
  .videoPlayer {
    width: 880px;
  }

  .videoPlayer.contentActive {
    width: 730px;
  }

  .pointsContainer {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 950px) {
  .videoPlayer {
    width: 700px;
  }
}

@media only screen and (min-width: 650px) and (max-width: 768px) {
  .container {
    display: block;
  }

  .player {
    height: 200px;
  }

  .banner {
    height: 200px !important;
  }

  .videoPlayer {
    width: 600px;
  }
}

@media only screen and (max-width: 650px) {
  .videoPlayer {
    width: 420px;
  }

  .videoSections {
    width: 250px;
  }

  .videoTitleContainer {
    max-width: 150px;
  }

  .main {
    margin-left: 250px;
  }
}