.profileMain {
  background-color: #f1f5f9;
  min-height: 100vh;
}

.batchesContainer {
  display: "flex";
  flex-direction: "column"
}

.boxStyle {
  box-shadow: 0px 2px 1px -1px rgba(211, 217, 227, 0.3), 0px 1px 1px 0px rgba(211, 217, 227, 0.25), 0px 1px 3px 0px rgba(211, 217, 227, 0.22);
  color: rgb(71, 85, 105);
  border: 1px solid #d3d9e3 !important;
  padding: 12px;
}

.fieldPad {
  padding: 23px 16px 15px;
}

.title {
  color: rgb(71, 85, 105);;
}

.title >h3 {
  color: #666666;
}

.batchTitle {
  min-width: 80px;
  box-shadow: 0px 2px 1px -1px rgba(211, 217, 227, 0.3), 0px 1px 1px 0px rgba(211, 217, 227, 0.25), 0px 1px 3px 0px rgba(211, 217, 227, 0.22);
  color: rgb(49, 59, 71);
  border: 1px solid #d3d9e3 !important;
  text-align: center;
  font-weight: 600 !important;
}

.actionBtn {
  background-color: rgba(76, 40, 175, 0.85);
  color: #e5e9ee;
  border-radius: 5px;
  text-align: center;
  padding: 3px 8px;
  min-width: 70px;
  font-size: 14px;
}

.changeText {
  color: rgb(49, 59, 71) !important;
  cursor: pointer;
  font-weight: bold !important;
}

.changeText::after {
  content: "";
  position: relative;
  z-index: 99;
  width: 100%;
  display: block;
  height: 1.5px;
  background-color: rgb(49, 59, 71);
  color: black;
  bottom: -6px;
}
.boxStyle {
  background-color: #fff;
}

@media only screen and (min-width: 950px) {
  .boxStyle {
    width: 73%;
  }
}