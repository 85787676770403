.backBtn {
  cursor: pointer;
  color: rgb(100, 116, 139);
  max-width: 80px;
}

.backBtn>svg {
  height: 15px;
  width: 15px;
  margin-top: -2px;
}

.notificationAllHeader {
  display: flex;
  padding: 16px 0;
  align-items: center;
  color: #9e9e9e;
}

.notificationIcon {
  color: #F49D1A;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #f3de7f;
  border-radius: 50%;
  margin-right: 10px;
}

.notificationIcon svg {
  height: 32px;
  width: 32px;
}

.allNotificationText {
  font-size: 24px;
  color: rgb(30, 41, 59);
  line-height: 56px;
}

.notificationText {
  margin-top: 5px;
  font-size: 14px;
  padding-bottom: 10px;
}

.dateText {
  display: flex;
  align-items: center;
  font-size: 10px;
}

.redDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f06548;
  margin-right: 5px;
}

.pointer {
  cursor: pointer;
}

.playIcon,
.quizIcon,
.pointsIcon,
.coinsIcon {
  margin-right: 5px;
  margin-top: -1px;
}

.notificationType {
  display: flex;
  align-items: center;
}

.playIcon svg {
  width: 18px;
  height: 18px;
  color: #C73659;
}

.typeVideoText {
  color: #C73659;
}

.quizIcon svg {
  width: 16px;
  height: 16px;
  color: #FFC94A;
}

.typeQuizText {
  color: #FFC94A;
}

.typePointsText {
  color: #7469B6;
}

.typeCoinsText {
  color: #FFC100;
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  color: #9e9e9e;
}

.notification {
  padding: 10px 15px;
  border-bottom: 1px solid #ccc;
}

.notificationMainHeader {
  position: sticky;
  top: 0;
  background-color: #f1f5f9;
}

.something {
  color : #eaf0f7;
}

.notificationMainHeader {
  padding-top: 28px;
}

.paginationContainer {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-evenly;
}

.paginationBts {
  background-color: #f1f5f9;
}
