.mainContainer {
  background-color: #f1f5f9;
  display: flex;
}

.leftDsaContent {
  width: 35%;
  padding-top: 20px;
  padding-left: 20px;
}

.dsaTitle {
   color: #666666;
}