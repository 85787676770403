.noNotifications {
  height: 200px;
}

.playIcon, .quizIcon, .pointsIcon, .coinsIcon{
  margin-right: 5px;
  margin-top: -1px;
}

.playIcon svg {
  width: 18px;
  height: 18px;
  color: #C73659;
}

.notification {
  color: #31363F;
  padding: 10px 0;
  margin: 0 12px;
  border-bottom: 1px solid #dddbdb;
  font-size: 12px;
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  color: #9e9e9e;
}

.notificationType {
  display: flex;
  align-items: center;
}

.notificationText {
  margin-top: 5px;
}

.typeVideoText {
  color: #C73659;
}

.dateText {
  display: flex;
  align-items: center;
  font-size: 10px;
}

.redDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f06548;
  margin-right: 5px;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noNotifications {
  color: #9e9e9e;
}

.pointer {
  cursor: pointer;
}

.typeQuizText {
  color: #FFC94A;
}

.quizIcon svg {
  width: 16px;
  height: 16px;
  color: #FFC94A;
}

.typePointsText {
  color: #7469B6;
}

.typeCoinsText {
  color: #FFC100;
}

.notificationsMain {
  height: 200px;
  width: 300px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

.notificationWrapper {
  overflow: hidden;
  overflow-y: auto;
  height: 172px;
}

.notificationWrapper::-webkit-scrollbar {
  width: 4px;
}

.notificationWrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.notificationWrapper::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.notificationsMain::-webkit-scrollbar {
  width: 4px;
}

.notificationsMain::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.notificationsMain::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.allBtnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  padding: 3px 12px 3px 0;
  color: #9e9e9e;
  cursor: pointer;
}

.allBtnContainer:hover {
  text-decoration: underline;
}