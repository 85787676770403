.container {
  display: flex;
  height: 80vh;
}

.left {
  position: relative;
  width: 20%;
  height: 100%;
  overflow-y: auto;
  border-right: 2px solid #ccc;
  padding-right: 20px;
}

.left div {
  border: 1px solid #E9EFEC;
  padding: 10px;
  margin: 5px 5px 12px;
  background-color: #f5f5f5;
  border-radius: 3px;
}

.right {
  width: 80%;
  padding: 20px;
  background-color: #eaeaea;
}

.headerText {
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  max-width: 600px;
  position: sticky;
  top: 0;
  padding: 0;
  margin: 0;
}

.search-input {
  width: 100%;
  padding: 12px 20px;
  font-size: 12px;
  border: 1px solid #dfe1e5;
  border-radius: 24px;
  box-shadow: 0px 1px 6px rgba(32, 33, 36, 0.28);
  outline: none;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: #4285f4;
  box-shadow: 0px 1px 8px rgba(66, 133, 244, 0.5);
  font-size: 12px;
}

.search-button {
  position: absolute;
  right: 15px;
  background-color: #5735B4;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 24px;
  cursor: pointer;
  font-size: 12px;
  outline: none;
}

.search-button:hover {
  background-color: #4d2aae;
}