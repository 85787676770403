/* Accordion.css */

.accordion {
    min-width: 150px;
    margin: 20px auto;
  }
  
  .accordion-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .accordion-content {
    padding: 20px;
    background-color: #ecf0f1;
    border: 1px solid #bdc3c7;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  .accordion-content p {
    margin: 0;
    color: #333;
  }
  