.coursesMain {
  background-color: #f1f5f9;
  min-height: 100vh;
}

.courses {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.course {
  border-bottom: 1px solid #ccc;
}
.courseTitle {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #6b78ac;
  font-family: 'Sofia Pro', sans-serif;
}

.courseHeadline {
  color: #898fa7;
  font-weight: bold;
  font-size: 14px;
  margin-top: 0.8rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.durationContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.6rem;
}

.totalDuration,
.totalLectures {
  color: #8b8e9e;
  font-size: 13px;
  font-weight: lighter;
  font-style: italic;
}

.banner {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.headerContainer {
  display: flex;
  max-height: 55px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.headerText {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerText > h4 {
  margin: 0;
  color: #666666;
}

.searchContainer>div {
  margin: 0;
}

.continueBtn {
  padding: 5px 5px 5px 12px;
  border-radius: 5px;
  color: #134094c1;
  background-color: #FFF;
  font-size: 12px;
  border: 1px solid #2d52978f;
  transition: color 0.1s ease-in, background-color 0.1s ease-in;
  letter-spacing: 0.6px;
}

.continueBtn:hover {
  color: #fff;
  background-color: #134094c1;
}

.wrapper {
  width: 100%;
  /* max-width: 31.25rem; */
  max-width: 250px;
  margin: 6rem auto;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.2rem;
  background: #fff;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 15px;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}