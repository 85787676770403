.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(92, 106, 129, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(5px);
}

.modalContent {
  position: relative;
  outline: none;
  overflow: hidden;
  width: 80%;
  max-width: 800px;
  min-height: 40vh;
  background-color: #fff;
  filter: none;
  border-radius: 8px;
  top: -150px;
  z-index: 1001;
}

.searchModelMain {
  padding: 12px 18px 18px 20px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1002;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  overflow: hidden;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
}

.closeIconContainer {
  cursor: pointer;
}

.globalSearchContainer {
  display: flex;
  margin: 12px 0 0;
  padding: 9px 13px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: start;
  border: none;
  outline: none;
  background: transparent;
  caret-color: rgb(226, 232, 240);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: text;
  border-radius: 8px;
  border: 1px solid rgba(148, 163, 184, 0.85);
  color: rgba(148, 163, 184, 0.85);
  background-color: rgba(148, 163, 184, 0.2);
}

.globalSearchInput {
  width: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: start;
  color: rgb(104, 122, 150);
  border: none;
  background: transparent;
  font-style: normal;
  outline: none;
  margin-left: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  caret-color: rgb(135, 167, 210);
}

.globalSearchInput::placeholder {
  color: rgb(140, 162, 192);
}

.suggestionWrapper {
  display: flex;
  flex-wrap: wrap;
}

.suggestion {
  cursor: pointer;
  font-size: 14px;
  padding: 4px 8px;
  border: 1px solid rgba(148, 163, 184, 0.85);
  color: rgba(148, 163, 184);
  border-radius: 5px;
  background-color: rgba(148, 163, 184, 0.2);
  margin-right: 10px;
}

.suggestionContainer {
  padding-left: 15px;
}

.topSearchText {
  margin-bottom: 10px;
  color: rgb(104, 122, 150);
}

.intructionTextContainer {
  margin: 5px 0 20px;
}

.intructionText {
  font-size: 12px;
  color: rgb(148, 163, 184);
}

.searchResultsText {
  margin-bottom: 10px;
  color: rgb(144, 159, 183);
}

.resultsContainer {
  padding-left: 18px;
}

.tabsContainer {
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
}

.tabsContainer>div {
  padding: 0 10px 10px;
  cursor: pointer;
  margin-right: 10px;
  color: rgba(148, 163, 184);
}

.tabsContainer>div:first-child {
  /* padding-left: 0; */
}

.tabsContainer .activeTab {
  border-bottom: 3px solid rgb(104, 122, 150);
  color: rgb(95, 113, 142);
}

.tabsContainer .tab {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-style: italic;
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  color: rgb(95, 113, 142);
}

.tabContent {
  overflow: auto;
  min-height: 15vh;
  max-height: 20vh;
}

.quiz,
.course,
.resource {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid rgb(148, 163, 184);
}

.quiz:hover,
.course:hover,
.resource:hover {
  background-color: rgb(238, 242, 247);
  border-radius: 8px;
}

.quizText,
.courseText,
.resourceText {
  font-size: 14px;
}

.searchText {
  color: rgb(95, 113, 142);
}