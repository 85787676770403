.container {
  text-align: center;
  background-color: #edf7fc;
}

.subtitle {
  color: #f60840;
  font-size: 14px;
  letter-spacing: 0.64px;
  font-weight: 600;
}

.title {
  font-size: 24px;
  color: #2a2f32;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: 1px;
}

.forgotPass {
  text-transform: capitalize;
  font-size: 14px;
  color: blue;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 800px) {
  .container {
    width: 100%;
    padding: 0 1rem;
  }
}

.Foot {
  /* margin-top: 50%; */
}

.logoContainer {
  text-align: center;
  padding-bottom: 30px;
}

.logoContainer>img {
  width: 250px;
  height: auto;
}

.additional-content>p {
  font-size: 14px;
  height: 25px;
}

.cardWrapper {
  display: flex;
  width: 100%;
  background-color: #f1f5f9;
  height: 100vh;
  text-align: left;
}

.cardContainer {
  flex: 1;
}

.card {
  position: relative;
  border-radius: 10px;
  width: 300px;
  height: 180px;
  background-color: #f7f8f9;
  padding: 15px 0 0 20px;
}

.centerDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
}

.rightSideCardContainer {
  justify-content: space-evenly;
}

.cardHeader {
  color: #3C5B6F;
  font-size: 15px;
}

.valueContainer {
  color: rgb(49, 59, 71);
  font-weight: 600;
  font-size: 20px;
  margin-top: 10px;
}

.plusContainer {
  color: #41B06E;
}

.imgCard {
  width: 150px;
  height: 150px;
  bottom: 0;
  right: 20px;
  position: absolute;
  border-bottom-right-radius: 10px;
}

.imgCard img {
  width: 150px;
  height: 150px;
}

.main {
  min-height: 100vh;
  background-color: #edf7fc;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.haveAccount {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media only screen and (min-width: 1000px) {
  .rightPanel {
    display: table-cell;
    width: 55%;
    height: 100%;
    background: #f1f5f9;
    bottom: 0px;
    position: absolute;
    overflow: hidden;
    top: 0px;
    right: 0px;
    padding-left: 0;
    padding-right: 0;
    float: left;
  }

  .leftPanel {
    padding: 0 2em 0 5em;
  }

  .rightPanel>.background-wrapper {
    background-image: url("../../../assets/right-panel.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    text-align: start;
  }

  .container {
    /* display: table-cell; */
    height: 100%;
    width: 45%;
    padding-left: 0;
    padding-right: 0;
    float: left
  }

  .main {
    height: 100vh;
    display: table;
    width: 100%;
    margin: 0px;
    float: none;
  }

  .container-fluid {
    bottom: 0px;
    position: absolute;
    padding: 10px 40px 20px;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%) !important;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .right-heading {
    color: #fff;
    width: 100%;
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 1px;
    padding-bottom: 3px;
  }

  .content {
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
    width: 100%;
    font-weight: 500;
    padding-bottom: 15px;
    letter-spacing: 1px;
  }

  .additional-content {
    color: #fff;
    padding-top: 18px;
    font-size: 16px;
    line-height: 2.5;
    width: 100%;
    font-weight: 300;
  }

  .learnMore {
    display: flex;
    font-size: 12px;
    color: rgb(102, 84, 155);
    background-color: #f2e0e013;
    font-weight: bold;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    width: fit-content;
  }

  .learnMore:hover {
    color: rgb(49, 34, 100);
  }

  .learnMore>.learn {
    border-bottom: 1px solid;
    padding-bottom: 2px;
    display: inline-block;
    font-weight: bold;
  }

  .learnMore>.arrow {
    font-weight: lighter;
    font-family: system-ui;
    padding-left: 10px;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
  }

  .Foot {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .Foot {
    display: block;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1000px) {
  .rightPanel {
    display: none;
  }

  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .leftPanel {
    padding: 4em 0 0;
  }

  .Foot {
    /* margin-top: 120vh; */
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  /* Tablets and smaller screens */
  .Foot {
    /* margin-top: 4em; */
  }

  .rightPanel {
    display: none;
  }

  .container {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .rightPanel {
    display: none;
  }

  .container {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin: 0 auto;
    padding-top: 3em;
  }

  .subtitle {
    font-size: 12px;
  }

  .Foot {
    /* margin-top: 4em; */
  }
}