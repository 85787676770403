.contentItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 0.67px solid #d3d9e3;
  font-size: 12px;
  background-color: rgb(250, 250, 250);
}

.contentItem:last-child {
  margin-bottom: 0;
}

.contentItem>div {
  margin-right: 18px;
}

.contentItem>div:last-child {
  margin-right: 0;
}

.container {
  padding: 0 15px 15px;
  border-radius: 5px;
  color: rgb(71, 85, 105);
  background-color: #fff;
}

.contentHead {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.duration {
  font-weight: 600;
}

.content>div {
  margin-bottom: 20px;
}

.viewBtn {
  background-color: #6C22A6;
  color: #e5e9ee;
  border-radius: 3px;
  text-align: center;
  padding: 3px 8px;
  min-width: 90px;
}

.itemName {
  min-width: 200px;
  max-width: 200px;
  color: rgb(51, 65, 85);
  ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quizName {
  letter-spacing: 0.5px;
  color: rgb(71, 85, 105);
}

.sectionTitle {
  color: #153448;
}

.seeAll {
  color: rgba(76, 40, 175, 0.85);
  cursor: pointer;
  font-size: 14px;
  line-height: 25px;
}

.dashboardSidebar {
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 rgba(0, 0, 0, 0);
  /*For Firefox */

}

.some {
  width: 300px;
  height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 rgba(0, 0, 0, 0);
  /* For Firefox */
}

.dashboardSidebar::-webkit-scrollbar {
  width: 4px;
}

.dashboardSidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.dashboardSidebar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.progressbarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressbarContainer svg {
  background: #fff;
  border-radius: 50%;
}

.something {
  color: #f3deb7;
}

.notificationIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424769;
  cursor: pointer;
  width: 42px;
  height: 42px;
  transition: background-color 0.6s ease, border-radius 0.6s ease;
}

.notificationIcon svg {
  width: 25px;
  height: 25px;
}

.notificationIcon:hover {
  background-color: #e2ebf4;
  border-radius: 50%;
}

.notificationActiveIcon {
  background-color: #e2ebf4;
  border-radius: 50%;
}

.notificationsCount {
  position: absolute;
  right: 0;
  width: 18px;
  height: 15px;
  color: #fff;
  background-color: rgb(240, 101, 72);
  font-size: 10px;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
}

.main {
  position: relative;
}

.main::-webkit-scrollbar {
  display: none !important;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar { 
  display: none;  /* WebKit-based browsers */
}

.notificationContainer {
  position: absolute;
  z-index: 999;
  top: 43px;
  background-color: #fcfbf9;
  right: 2px;
}

@media only screen and (min-width: 1200px) {
  .dashboardSidebar {
    width: 400px;
  }
}

@media only screen and (min-width: 950px) {
  .dashboardSidebar {
    width: 330px;
  }
}

@media only screen and (max-width: 950px) {
  .main {
    overflow-x: auto;
    white-space: nowrap;
  }

  .container {
    min-width: 500px;
  }
  .dashboardSidebar {
    display: none;
  }
}

@media only screen and (min-width: 950px) {

  .main {}

}