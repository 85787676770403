@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

:root {
  /* colors */
  --hue-color: 344;
  --primary: rgba(76, 40, 175, 0.94);
  --secondary: #0c0e2b;
  --title-color: #313b47;
  --text-color: #000;
  --body-color: #fff;
  --primary-rgb: rgb(95, 45, 237);
  --primary-rgba: rgba(76, 40, 175, 0.85);
  /* fonts */
  --body-font:'Gordita Regular', sans-serif;
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  --font-medium: 500;
  --font-semi-bold: 600;

  --footer-height: 250px;

  /* margins */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.4rem;
  --mb-3: 3rem;
  --mb-3-5: 3.5rem;
  --mb-4: 4rem;

  /* z-index */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

  --header-height: 80px;
  --sidebar-width: 280px;
  --max-width: 1200px;
}

::selection {
  background: var(--primary-rgba);
  color: #fff;
}

/* scroll bar */

/* ::-webkit-scrollbar {
  width: 0.4rem;
  background-color: var(--primary-rgba);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary);
} */

::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 4px; /* Rounded corners */
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0); /* Color of the track */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color) !important;
  color: var(--text-color);
  overflow-x: hidden;
}

body.fixed {
  height: 100vh;
  overflow: hidden;
}

html,
body {
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

a {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

.text-danger {
  color: brown;
}

.tc {
  text-align: center !important;
}

.tt-up {
  text-transform: uppercase;
}

.tt-lo {
  text-transform: lowercase;
}

.tt-ca {
  text-transform: capitalize;
}

.text-muted {
  color: #aaa;
}

.text-light-black {
  color: #333333;
}

.cp {
  cursor: pointer;
}

.primaryColor {
  color: var(--primary) !important;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textCapital {
  text-transform: capitalize;
}

.textLower {
  text-transform: lowercase;
}

.textUpper {
  text-transform: uppercase;
}

@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

.heading {
  font-size: 33px;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0);
  position: relative;
}

.text-success {
  color: green;
}

.text-muted {
  color: grey;
}

.subHeading {
  font-size: 20px;
  margin-top: 20px;
  color: var(--secondary);
}

.section {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 50px;
  overflow-x: hidden;
}

.mainContent {
  max-width: var(--max-width);
  margin: auto;
  padding: 20px 20px;
  /* margin-top: var(--header-height); */
}

.ml-0-5 {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-1-5 {
  margin-left: 1.5rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.ml-2-5 {
  margin-left: 2.5rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}
/* margin right */
.mr-0-5 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-1-5 {
  margin-right: 1.5rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mr-2-5 {
  margin-right: 2.5rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

/* margin bottom */
.mb-0-5 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-1-5 {
  margin-bottom: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-2-5 {
  margin-bottom: 2.5rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

/* margin top */
.mt-0-5 {
  margin-top: 0.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-1-5 {
  margin-top: 1.5rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-2-5 {
  margin-top: 2.5rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

/* center the div */
.center-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* drafter */
.wrapper-class {
  /* padding: 1rem; */
  /* border: 1px solid #ccc; */
}
.editor-class {
  background-color: white;
  height: 200px;
  padding: 1rem 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.fullscreen-enabled {
  background-color: #fff !important;
}

button {
  outline: none;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-family: 'Sofia Pro', sans-serif !important;
}
th {
  font-weight: 600 !important;
}