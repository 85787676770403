.backBtn {
  cursor: pointer;
  color: rgb(100, 116, 139);
  max-width: 80px;
}

.backBtn > svg {
  height: 15px;
  width: 15px;
  margin-top: -2px;
}

.leaderboardHeader {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}

.leaderboardIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0891b2;
  height: 56px;
  width: 56px;
  margin-right: 10px;
  border-radius: 28px;
  background-color: #cffafe;
}

.leaderboardIcon>div>svg {
  height: 32px;
  width: 32px;
}

.leaderboardText {
  font-size: 24px;
  color: rgb(30, 41, 59);
  line-height: 56px;
}

.tabsListWrapper {
  padding: 0 24px;
}

.tabsContainer {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(241, 245, 249);
  ;
  padding: 2px;
  height: 40px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: auto;
  border: none;
}

.tab {
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: normal;
  color: rgb(100, 116, 139);
  background-color: transparent;
  cursor: pointer;
  text-transform: capitalize;
  padding: 0px;
  border-radius: 2px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  line-height: 34px;
  box-sizing: border-box;
}

.active {
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: normal;
  cursor: pointer;
  text-transform: capitalize;
  padding: 0px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  line-height: 34px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  color: rgb(51, 65, 85);
  border: 1px solid rgb(203, 213, 225);
  box-shadow: rgba(40, 41, 61, 0.08) 0px 0px 1px, rgba(96, 97, 112, 0.16) 0px 1px 2px;
  border-radius: 8px;
}


.dailyLeaderboardCard {
  border-radius: 16px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: inherit;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: rgb(254, 243, 199);
  background-size: 100%;
  padding: 16px 0px;
}

.weeklyLeaderboardCard {
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: inherit;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: rgb(165, 166, 236);
  background-size: 100%;
  padding: 16px 0px;
  color: #fff;
}

.ribbonContainer {
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
  margin-top: 6px;
  color: rgb(51, 65, 85);
}

.weekRibbonContainer {
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
  margin-top: 6px;
  color: #fff;
}

.calenderTagContainer,
.calenderTagContainer>div {
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
  -webkit-box-pack: center;
  align-items: center;
}

.calenderTagContainer>div {
  border-radius: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 2px 9px;
  margin-top: 8px;
  background-color: rgba(245, 158, 11, 0.24);
}

.calenderTagWeek>div {
  background-color: rgb(132, 133, 211);
}

.winners {
  width: 100%;
}

.iconContainer svg {
  width: 15px;
  height: 15px;
  color: rgb(146, 64, 14);
  margin-top: -13px;
  margin-right: 3px;
}

.dateText {
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgb(146, 64, 14);
}

.dateTextForWeek {
  color: #fff;
  font-size: 11px;
}

.pointsIcon,
.coinsIcon {
  margin-right: 4px;
}

.iconContainer {
  height: 15px;
}

.iconForWeek svg {
  width: 15px;
  height: 15px;
  color: #fff;
  margin-top: -12px;
}

.winnerRowCard {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 38px;
  padding: 0px 16px;
  margin: 14px 0px;
}

.winnerRowCard>div {
  margin-right: 12px;
}

.winnerRowCard>.studentName {
  flex: 1 1 0%;
  min-width: 0px;
  margin-right: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
  color: rgb(51, 65, 85);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.studentNameWeek {
  flex: 1 1 0%;
  min-width: 0px;
  margin-right: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointsContainer,
.coinsContainer {
  display: flex;
  align-items: center;
}

.headerTextTodays {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
}

.headerTextTodays {
  background-color: #e0e7ff;
}

.lbtext {
  white-space: pre-wrap;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgb(30, 41, 59);
  font-size: 18px;
}

.lbdateText {
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgb(71, 85, 105);
}

.nameContainer {
  display: flex;
  align-items: center;
}

.nameContainer>div {
  margin-right: 5px;
}

.nameContainer>div:last-child {
  margin-right: 0;
}

.studentRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 16px 24px;
}

.studentRow .rankContainer {
  margin-right: 25px;
}

.studentNameText {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
  color: rgb(71, 85, 105);
  margin-left: 4px;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.listDiv>div:first-child {
  background-color: #fef3c7;
  border-left: 4px solid rgb(251, 191, 36);
}

.listDiv>div:nth-child(2) {
  background-color: #e2e8f0;
  border-left: 4px solid rgb(148, 163, 184);
}

.listDiv>div:nth-child(3) {
  background-color: #f1ebe7;
  border-left: 4px solid rgb(180, 83, 9);;
}

.headSectionContainer {
  position: sticky;
  padding: 24px;
  top: 0;
  z-index: 10;
  background: #fff;
}
