.playgroundMain {
  background-color: #f1f5f9;
  min-height: 100vh;
}

.formControl {
  margin-bottom: 10px;
}

#themeSelect {
  min-width: 120px;
  font-size: 0.875rem;
  padding: 10px;
}

#themeSelect option {
  min-height: 50px;
  padding: 4px;
}

.actionBtns {
  background-color: rgba(76, 40, 175, 0.85);
  color: #e5e9ee;
  border-radius: 3px;
  text-align: center;
  padding: 3px 8px;
  min-width: 55epx;
  font-size: 14px;
}

.dropdown>div {
  padding: 5px 25px 5px 10px !important;
}

.output pre {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  background-color: #282a36;
  color: #CCCCCC;
  padding: 10px;
  border-radius: 5px;
  line-height: 1.5;
  white-space: pre-wrap;
  height: 473px;
  margin-bottom: 0;
}

.output pre code {
  white-space: pre-wrap;
}

.editorContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.tabsContainer {
  display: none;
}

.tabsContainer button {
  width: 70px;
  text-align: center;
  height: 30px;
  color: #cccccc;
  background-color: #201a2c;
}

.tabsContainer button:first-child {
  border-top-left-radius: 5px;
}

.tabsContainer button:last-child {
  border-top-right-radius: 5px;
}

.tabsContainer .activeTab {
  background-color: #282a36;
}

.playgroundHeader {
  margin-bottom: 15px;
}

.playgroundHeader>h3 {
  color: #666666;
}

@media only screen and (max-width: 500px) {
  .editorContainer {
    grid-template-columns: 1fr;
  }

  .tabsContainer {
    display: block;
  }

  .output pre {
    height: 500px;
    border-radius: 0px 5px 5px;
  }
}