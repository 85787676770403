.reportsMain {
  background-color: #f1f5f9;
  min-height: 100vh;
}

.resultImg {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.headerContainer {
  display: flex;
  max-height: 55px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.headerText {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerText > h4 {
  margin: 0;
  color: #666666;
}

.searchContainer>div {
  margin: 0;
}

.wrapper {
  width: 100%;
  /* max-width: 31.25rem; */
  max-width: 250px;
  margin: 6rem auto;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.2rem;
  background: #fff;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 15px;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

@media only screen and (min-width: 900px) {
  .reportsTable {
    max-width: 85vw !important;
  }
}
